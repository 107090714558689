.popoverContainer {
  --scrollbar-size: 8px;
  z-index: 1600;

  .popover {
    display: flex;
    flex-direction: column;
    // padding: 16px 24px;
    border-radius: 16px;
  }
}
