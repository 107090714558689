* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'CircularStd', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif !important;
}

body {
  background-color: var(--color-onSecondary);
  color: var(--color-text);
}

@font-face {
  font-family: 'CircularStd';
  src: url(./font/CircularStd-Bold.ttf) format('truetype');
  font-weight: bold;
}

@font-face {
  font-family: 'CircularStd';
  src: url(./font/CircularStd-Book.ttf) format('truetype');
  font-weight: normal;
}

@font-face {
  font-family: 'CircularStd';
  src: url(./font/CircularStd-Medium.ttf) format('truetype');
  font-weight: 500;
}

h1,
.h1 {
  font-size: 3rem;
  line-height: 110%;
  letter-spacing: -0.25px;
  font-weight: bold;
}

h2,
.h2 {
  font-size: 2rem;
  line-height: 110%;
  letter-spacing: -0.25px;
  font-weight: bold;
}

h3,
.h3 {
  font-size: 1.5rem;
  line-height: 110%;
  letter-spacing: 0px;
  font-weight: bold;
}

h4,
.h4 {
  font-size: 1.25rem;
  line-height: 120%;
  letter-spacing: 0px;
  font-weight: bold;
}

h5,
.h5 {
  font-size: 1rem;
  line-height: 120%;
  letter-spacing: 0px;
  font-weight: bold;
}

h6,
.h6 {
  font-size: 0.75rem;
  line-height: 120%;
  letter-spacing: 0px;
  font-weight: bold;
}

.text-1 {
  font-size: 1.25rem;
  line-height: 130%;
  letter-spacing: 0px;
  font-weight: normal;
}

.text-2 {
  font-size: 1rem;
  line-height: 140%;
  letter-spacing: 0px;
  font-weight: normal;
}

.text-3 {
  font-size: 0.875rem;
  line-height: 140%;
  letter-spacing: 0px;
  font-weight: normal;
}

.text-4 {
  font-size: 0.75rem;
  line-height: 140%;
  letter-spacing: 0px;
  font-weight: normal;
}

.text-5 {
  font-size: 0.675rem;
  line-height: 140%;
  letter-spacing: 0px;
  font-weight: 500;
}

.weight-regular {
  font-weight: normal;
}

.weight-bold {
  font-weight: bold;
}

.weight-medium {
  font-weight: 500;
}

.caps {
  text-transform: uppercase;
}

.block {
  display: block;
}

.button {
  font-size: 1rem;
  line-height: 120%;
  letter-spacing: 0px;
  font-weight: 500;
}

.color-grey-6 {
  color: var(--color-grey-6, #718096);
}

.align-left {
  text-align: left;
}

.align-right {
  text-align: right;
}

.align-center {
  text-align: center;
}
