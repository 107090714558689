.step-by-step {
  display: flex;
  flex-direction: column;

  .step-style {
    display: flex;

    &:not(:last-child) {
      margin-bottom: 70px;
    }

    .step-number {
      margin-right: 16px;
    }

    .text-and-img {
      display: flex;
      justify-content: space-between;
      max-width: 1182px;

      .text {
        width: 60%;
      }

      div {
        max-width: 635px;
        margin-left: 85px;
        height: 100%;
        width: 100%;

        &:hover {
          cursor: pointer;
        }

        img {
          object-fit: fill;
          height: 100%;
          width: 100%;
        }
      }
    }
  }

  @media screen and (max-width: 630px) {
    .step-style {
      &:not(:last-child) {
        margin-bottom: 1.5rem;
      }

      .step-number {
        margin-right: 0.5rem;
      }

      .text-and-img {
        flex-direction: column;
        .text {
          width: 100%;
        }

        div {
          margin: 1rem 0 0 0;
        }
      }
    }
  }
}
