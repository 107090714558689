.selectInput {
  background-color: aqua;
  width: 100%;
  & > div {
    padding: 10px 16px;
    background: var(--color-bg);
    border-radius: 16px;
  }

  .MuiSelect-icon {
    right: 6px;
  }
}

.select-menu {
  div.MuiPaper-rounded {
    border-radius: 16px;
  }
}

.select-MenuItem {
  min-height: 20px;

  &:not(:last-child) {
    margin-bottom: 12px;
  }
}

.MuiSelect-select.MuiSelect-select {
  width: 70px;
  padding-right: 16px !important;

  &:focus {
    background-color: transparent;
  }
}

.MuiPaper-root {
  margin-top: 40px;
  box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
  background: var(--color-grey-1);
  border-color: transparent;
  max-height: 300px;
  transition: 0s !important;

  .MuiListItem-root.Mui-selected {
    background-color: var(--color-grey-1);
  }

  &.MuiPaper-elevation8 {
    box-shadow: 0px 2px 10px rgb(0 0 0 / 10%), 0px 0px 2px rgb(0 0 0 / 20%);
  }

  ul {
    display: flex;
    flex-direction: column;
    background-color: var(--color-white);
    padding: 16px;

    li {
      &.select-MenuItem {
        background-color: transparent;
        padding: 0;
      }
      span {
        background-color: transparent;

        color: var(--color-grey-6);
        &:hover {
          color: var(--color-black);
          background-color: transparent;
        }
      }

      &.Mui-selected {
        span {
          background-color: transparent;
          color: var(--color-black);
        }
        &:hover {
          background-color: transparent;
        }
        &:focus {
          background-color: transparent;
        }
      }
    }
  }
}
