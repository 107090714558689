.homepage-screen {
  background-color: var(--color-white);

  .homepage-tiles {
    display: flex;
    align-items: stretch;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 16px;

    .tile-style {
      margin-top: 16px;
    }
  }

  .select-option-margin {
    max-width: 1250px;
    margin: 40px 0 60px;
  }

  .card-width {
    max-width: 700px;
  }
}
