.lightbox-component {
  max-width: 100%;
  max-height: 100%;

  img {
    max-height: 100%;
    max-width: 100%;
    width: auto;
    height: auto;
  }

  .lightbox {
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1;
    padding: 56px;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.45);

    button {
      margin: 16px;

      svg {
        fill: white;
      }
    }
  }
}
