.tile {
  max-width: 280px;
  min-height: 200px;
  height: 100%;
  padding: 26px 20px;
  border: 1px solid var(--color-grey-6);
  top: 0;
  right: 0;
  transition: box-shadow 0.15s, top 0.15s, right 0.15s;
  background-color: var(--color-white);

  &:hover {
    z-index: 1;
    top: -8px;
    right: -8px;
    box-shadow: -8px 8px 0 var(--color-white),
      -8px 8px 0 1px var(--color-grey-6);
    transition: box-shadow 0.15s, top 0.15s ease -8px, right 0.15s ease -8px;
  }

  span:hover {
    color: var(--color-text-subdued);
  }

  h5:hover {
    color: var(--color-black);
  }

  .margin-top-20 {
    margin-top: 20px;
  }
}
