.snippet-code {
  padding: 16px;
  margin-top: 24px;
  background-color: var(--color-grey-2);
  border-radius: 6px;
  overflow-wrap: break-word;

  div {
    display: flex;
    justify-content: space-between;
    flex-wrap: nowrap;
  }

  .snippet-as-text {
    max-width: 70%;
  }

  .copy-btn {
    &:hover {
      span {
        color: var(--color-primary-3);
      }
    }
    svg {
      path {
        fill: var(--color-grey-6);
        stroke: transparent;
      }
      width: 1.2rem;
      height: 1.2rem;
    }
  }
}
