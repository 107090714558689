.footer {
  max-height: 523px;
  bottom: 0;
  left: 0;
  margin: 0;
  padding: 90px 75px 66px;
  border-top: 1px solid var(--color-btn-default);
  margin-top: 114px;

  .inner-footer {
    display: flex;
    justify-content: space-between;

    div img {
      max-width: 218px;
    }

    .section {
      h4 {
        margin-bottom: 20px;
      }

      .list {
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        text-align: left;

        .list-element {
          line-height: 32px;
          color: var(--color-text-subdued);
        }

        a {
          .list-element:hover {
            color: var(--color-black);
          }
        }
      }
    }
  }

  @media screen and (max-width: 630px) {
    padding: 1rem 2rem;
    margin-top: 1.2rem;

    .inner-footer {
      flex-direction: column;

      div img {
        max-width: 10rem;
      }

      .section {
        margin-top: 1.5rem;

        h4 {
          margin-bottom: 0.4rem;
        }
      }
    }
  }
}
