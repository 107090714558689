.link-btn-screen {
  .card-max-width {
    max-width: 900px;
  }

  .margin-top-100 {
    margin-top: 100px;

    .margin-bottom-80 {
      margin-bottom: 100px;
    }
  }

  #snippet-div-linkbtn {
    margin: 55px 0;
  }
}
