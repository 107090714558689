.platform-btns-and-title {
  margin-top: 90px;
  max-width: 930px;

  .platform-btns-div {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    margin-top: 50px;

    .btn.btn-default {
      width: 135px;
      height: 135px;
      border: 2px solid var(--color-grey-2);
      border-radius: 50%;
      background-color: white;
      margin-top: 8px;

      &:hover {
        border: 4px solid var(--color-grey-2);
      }
      &.isSelected {
        border: 4px solid var(--color-black);
      }
    }
  }
}
