.box {
  width: 100%;
  position: relative;
  height: auto;

  &.outline {
    border: 1px solid var(--color-border, #e2e8f0);
    border-radius: 0.25rem;
  }

  &.fullsize {
    height: 100%;
  }

  &.flex {
    display: flex;
  }

  &.inline-flex {
    display: inline-flex;
    width: auto;
    align-items: center;
  }

  &.el-0,
  &.el-1,
  &.el-2,
  &.el-3,
  &.el-4 {
    background: var(--color-paper);
    border-radius: 0.25rem;

    --color-scrollbar: var(--color-paper);
    --color-scrollbar-thumb: var(--color-paper);
  }

  &.el-1 {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.05);
    border-color: transparent;
  }

  &.el-2 {
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.05), 0px 0px 1px rgba(0, 0, 0, 0.25);
    border-color: transparent;
  }

  &.el-3 {
    box-shadow: 0px 2px 10px rgba(0, 0, 0, 0.1), 0px 0px 2px rgba(0, 0, 0, 0.2);
    border-color: transparent;
  }

  &.el-4 {
    box-shadow: 0px 8px 40px rgba(0, 0, 0, 0.2), 0px 0px 4px rgba(0, 0, 0, 0.1);
    border-color: transparent;
  }

  &.background {
    background-color: var(--color-bg);
    --color-scrollbar: var(--color-bg);
    --color-scrollbar-thumb: var(--color-bg);
  }

  &.underline {
    border-bottom: 1px solid var(--color-border, #e2e8f0);
  }

  &.corner {
    border-radius: 0px;
  }

  &.hide-on-sm {
    @media screen and (max-width: 600px) {
      display: none;
    }
  }

  &.hide-on-md {
    @media screen and (max-width: 850px) {
      display: none;
    }
  }

  &.hide-on-lg {
    @media screen and (min-width: 1000px) {
      display: none;
    }
  }

  &.small-gap {
    & > * {
      margin-right: 8px;
    }
    & > *:last-child {
      margin-right: 0px;
    }
  }

  &.medium-gap {
    & > * {
      margin-right: 16px;
    }
    & > *:last-child {
      margin-right: 0px;
    }
  }

  &.large-gap {
    & > * {
      margin-right: 16px;
    }
    & > *:last-child {
      margin-right: 0px;
    }
  }
}
