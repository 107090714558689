:root {
  --color-primary: #10ccb9;
  --color-primary-1: #0da797;
  --color-primary-2: #0a8276;
  --color-primary-3: #075d54;
  --color-primary-4: #043832;
  --color-primary-5: #011311;

  --color-onPrimary: #011311;

  --color-secondary: #6369d1;
  --color-secondary-1: #545ad0;
  --color-secondary-2: #000000;
  --color-secondary-3: #000000;

  --color-onSecondary: #ffffff;

  --color-tertiary: #ee4266;
  --color-tertiary-1: #f26683;
  --color-tertiary-2: #f4869d;
  --color-tertiary-3: #f7a3b4;

  --color-critical: #cc1023;

  --color-warning: #ffa500;

  --color-success: #49b800;

  --color-grey-1: #f7fafc;
  --color-grey-2: #edf2f7;
  --color-grey-3: #e2e8f0;
  --color-grey-4: #cbd5e0;
  --color-grey-5: #a0aec0;
  --color-grey-6: #718096;
  --color-grey-7: #4a5568;
  --color-grey-8: #2d3748;
  --color-grey-9: #1a202c;

  --color-white: #ffffff;
  --color-black: #000000;
}

/* Light Mode */
:root {
  --color-bg: var(--color-grey-1);
  --color-paper: var(--color-white);
  --color-btn-default: var(--color-grey-2);

  --color-text: var(--color-primary-5);
  --color-text-subdued: var(--color-grey-6);

  --color-border: var(--color-grey-3);
  --color-disabled: var(--color-grey-4);
}

// .darkMode {
//   --color-bg: var(--color-primary-5);
//   --color-paper: var(--color-grey-9);
//   --color-btn-default: var(--color-grey-8);

//   --color-text: var(--color-white);
//   --color-text-subdued: var(--color-grey-5);

//   --color-border: var(--color-grey-7);
//   --color-disabled: var(--color-grey-6);
// }

.color-textPrimary {
  color: var(--color-text);
}

.color-primary {
  color: var(--color-primary);
}

.color-secondary {
  color: var(--color-secondary);
}

.color-error {
  color: var(--color-critical);
}

.color-subdued {
  color: var(--color-text-subdued);
}

.color-disabled {
  color: var(--color-disabled);
}

.color-tertiary {
  color: var(--color-tertiary);
}

body {
  background-color: var(--color-bg);
}

:root {
  --color-table-none: var(--color-grey-5);
  --color-table-none-chair: var(--color-grey-6);
  --color-table-none-text: var(--color-primary-5);

  --color-table-nonbookable: var(--color-grey-4);
  --color-table-nonbookable-chair: var(--color-grey-5);
  --color-table-nonbookable-text: var(--color-primary-5);
}

// .darkMode {
//   --color-table-none: var(--color-grey-8);
//   --color-table-none-chair: var(--color-grey-7);
//   --color-table-none-text: var(--color-white);

//   --color-table-nonbookable: var(--color-grey-7);
//   --color-table-nonbookable-chair: var(--color-grey-6);
//   --color-table-nonbookable-text: var(--color-primary-5);
// }
