.iconButtonSize {
  width: 16.67px;
  height: 16.67px;
}
.searchInput {
  padding: 3px;

  .MuiInputBase-input {
    font-size: 16px;
  }
}

#dropdown-btn {
  .MuiSvgIcon-root {
    color: rgba(0, 0, 0, 0.54);
    font-size: 1.5rem;
  }
}

.popoverContainer {
  // transition: opacity 246ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
  //   transform 164ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

  .popover {
    span:hover {
      color: var(--color-black);
    }

    .option-is-link:not(:last-child) {
      margin-bottom: 12px;
    }
  }
}
