* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  transition: background-color 100ms ease;
  transition: color 100ms ease;
  transition: border-color 100ms ease;

  &:disabled {
    cursor: not-allowed;
  }
}

p {
  margin: 0;
}

a {
  text-decoration: none;

  span:hover {
    color: var(--color-primary-3);
  }
  h5:hover {
    color: var(--color-primary-3);
  }
}

.body-margin {
  padding: 32px 75px;
}

.flex {
  display: flex;
  align-items: center;
}

.inline-flex {
  display: inline-flex;
  width: auto;
  align-items: center;
}

.space-between {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.center {
  display: flex;
  justify-content: center;
  align-items: center;
}

.fullSize {
  width: 100%;
  min-height: 100%;
}

.clickable {
  cursor: pointer;
}

.flex-column {
  display: flex;
  flex-direction: column;
  // align-items: center;
}

.inline-column {
  display: inline-flex;
  flex-direction: column;
}

.flex-grow {
  flex-grow: 10;
}

.flex-start {
  align-items: flex-start;
}

.flex-end {
  align-items: flex-end;
}

.scrollY {
  overflow-y: scroll;
}

.sb-show-main.sb-main-padded {
  padding: 0 !important;
}

.rounded {
  border-radius: 4px;
}

.mg-bt-xs {
  margin-bottom: 4px !important;
}

.mg-bt-sm {
  margin-bottom: 8px !important;
}

.mg-bt-md {
  margin-bottom: 16px !important;
}

.mg-bt-lg {
  margin-bottom: 32px !important;
}

.mg-r-xs {
  margin-right: 4px !important;
}

.mg-r-sm {
  margin-right: 8px !important;
}

.mg-r-md {
  margin-right: 16px !important;
}

.mg-r-lg {
  margin-right: 32px !important;
}

.pill {
  padding: 4px 8px;
  border-radius: 24px;
  margin-right: 8px;
  background-color: var(--color-border);
}

.flex-responsive {
  @media screen and (max-width: 600px) {
    flex-direction: column;
    align-items: stretch;

    & > * {
      width: 100%;
    }
  }
}

.colon {
  &::after {
    content: ':';
  }
}

.dateDropdownNav {
  position: absolute !important;
  left: calc(50vw - 105px);
  width: 210px !important;
}

/* Customize website's scrollbar like Mac OS
  Not supports in Firefox and IE */

body {
  --color-scrollbar: rgba($color: #fff, $alpha: 0.01);
  --color-scrollbar-thumb: var(--color-bg);
  --scrollbar-size: 16px;
  --scrollbar-height: 1;
}

/* total width */

*::-webkit-scrollbar {
  width: var(--scrollbar-size, 16px);
  height: calc(var(--scrollbar-size, 16px) * var(--scrollbar-height, 1));
  border-radius: calc(var(--scrollbar-size, 16px) / 4);
  background-color: var(--color-scrollbar);
}

.MuiPopover-root {
  *::-webkit-scrollbar {
    width: calc(var(--scrollbar-size, 16px) / 2);
    border-radius: calc(var(--scrollbar-size, 16px) / 4);
    background-color: var(--color-scrollbar);
  }

  *::-webkit-scrollbar-thumb {
    width: calc(var(--scrollbar-size, 16px) / 2);
    border-radius: calc(var(--scrollbar-size, 16px) / 4);
    background-color: var(--color-grey-5);
    border: calc(var(--scrollbar-size, 16px) / 8) solid
      var(--color-scrollbar-thumb);

    &.darkMode & {
      background-color: var(--color-grey-7);
    }
  }
}

/* background of the scrollbar except button or resizer */
*::-webkit-scrollbar-track {
  border-radius: calc(var(--scrollbar-size, 16px) / 4);
  background-color: var(--color-scrollbar);
}

/* scrollbar itself */
*::-webkit-scrollbar-thumb {
  border-radius: var(--scrollbar-size, 16px);
  background-color: var(--color-grey-5);
  border: calc(var(--scrollbar-size, 16px) / 4) solid
    var(--color-scrollbar-thumb);

  &.darkMode & {
    background-color: var(--color-grey-7);
  }
}

/* set button(top and bottom of the scrollbar) */
*::-webkit-scrollbar-button {
  display: none;
}
*::-webkit-scrollbar-corner {
  background-color: var(--color-scrollbar);
}

.fixed {
  position: fixed !important;
}

.MuiListItem-root {
  padding-left: 16px;
  padding-right: 16px;
}

@media screen and (max-width: 600px) {
  .body-margin {
    padding: 1rem 2rem;
  }
}