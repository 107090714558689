.navigation-bar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  max-height: 57px;
  max-width: 100%;
  top: 0;
  left: 0;
  margin: 0;
  padding: 8px;
  border-bottom: 1px solid var(--color-btn-default);

  img {
    max-height: 57px;
    padding: 8px;
    height: auto;
    width: auto;
  }

  .opt-and-lang-dropdowns {
    max-width: 200px;
    width: 100%;
    display: flex;
    justify-content: space-between;
  }

  .back-link {
    max-width: fit-content;

    .flex {
      display: flex;

      .MuiSvgIcon-root {
        fill: black;
        height: 12px;
        width: 12px;
      }
    }
  }

  @media screen and (max-width: 600px) {
    img {
      display: none;
    }

    .nav-website-title {
      font-size: 0.8rem;
    }

    .opt-and-lang-dropdowns {
      max-width: 55%;
      span {
        font-size: 0.8rem;
      }
    }
  }
}

.MuiSelect-root {
  width: 300px;
  .color-textPrimary {
    color: var(--color-grey-6);
  }
}
