.class-to-btn-screen {
  .card-max-width {
    max-width: 900px;
  }

  .margin-top-100 {
    margin-top: 100px;

    .margin-bottom-80 {
      margin-bottom: 100px;
    }
  }

  #class-to-btn {
    margin: 55px 0;
    background-color: var(--color-primary-1);

    span {
      color: var(--color-white);
    }
  }
}
